.App {
  text-align: center;
}

.full-width {
  width: 100%;
}

.align-center {
  text-align: center;
}

hr {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  max-width: 75%;
  border-color: #f0f0f0 !important;
}

.float-right {
  float: right;
}

.display-none {
  display: none;
}

.greyed-out {
  color: #979797;
}

.dark-grey {
  color: #7D7D7D;
}

.-green-text {
  color: #306D5C !important;
}

.navbar * {
  font-weight: 650;
  font-size: 16px;
}

.navbar a {
  color: #C25982;
}

.navbar a:hover {
  color: #C25982;
  cursor: pointer;
}

.navbar .scroll-link a:hover {
  color: #C25982 !important;
}

.navbar img {
  image-rendering: auto;
  image-rendering: crisp-edges;
  height: auto;
  max-width: 100%;
  width: 150px;
}

.Toastify__toast--success {
  background: #7B61FF;
}

.Toastify__toast--error {
  background: #322E87;
}

#signup-btn {
  color: white;
  background: #322E87;
  padding: 17px 40px;
  border-radius: 50px;
  margin-left: 1rem;
}

a.btn {
  color: white;
  border: none;
  background: #306D5C;
  padding: 17px 40px;
  border-radius: 50px;
  font-size: 15px;
  font-weight: 700;
}

a.btn:hover {
  color: white;
  border: none;
  background: #306D5C;
  padding: 17px 40px;
  border-radius: 50px;
  font-size: 15px;
  font-weight: 700;
}

a.btn.alt {
  color: #306D5C;
  border: none;
  background: white;
  padding: 17px 40px;
  border-radius: 50px;
  font-size: 15px;
  font-weight: 700;
}

a.btn.alt:hover {
  color: #306D5C;
  border: none;
  background: white;
  padding: 17px 40px;
  border-radius: 50px;
  font-size: 15px;
  font-weight: 700;
}

button.btn {
  color: white;
  border: none;
  background: #322E87;
  padding: 17px 40px;
  border-radius: 0px;
  font-size: 16px;
  font-weight: 400;
}

button.btn:hover {
  color: white;
  border: none;
  background: #322E87;
  padding: 17px 40px;
  border-radius: 0px;
  font-size: 16px;
  font-weight: 400;
}

#hero-section {
  min-height: 100vh;
  /* background-color: #322E8750; */
  background: url('./assets/images/heroImage1.png');
  background-size: 100% auto;
  background-position-y: 50px;
  background-repeat: no-repeat;
  color: white;
}

#hero-container {
  text-align: left;
}

.react-multi-carousel-list {
  min-height: 100vh;
} 

.react-multi-carousel-dot-list {
  margin-bottom: 0.5rem;
}

.react-multi-carousel-dot-list button{
  border: none;
  width: 10px;
  height: 10px;
}

.react-multi-carousel-dot--active button{
  background-color: #5751D3;
}

#hero-text-1 {
  margin-top: 13%;
}

#hero-text-1 div {
  margin-bottom: 3rem;
}

#hero-text-2 {
  margin-top: 10%;
}

h1 {
  font-size: 64px;
  font-weight: bold;
}

h2 {
  font-size: 32px;
  font-weight: bold;
}

h3 {
  font-size: 24px;
  font-weight: bold;
}

.semi-bold {
  font-weight: 600;
}

p {
  font-size: 24px;
  font-weight: 300;
}

.fs-14 {
  font-size: 14px;
}

.fs-16 {
  font-size: 16px;
}

.fs-18 {
  font-size: 18px;
}

.fs-20 {
  font-size: 20px;
}

img.dashboard-img {
  image-rendering: auto;
  image-rendering: crisp-edges;
  height: auto;
  max-width: 100%;
  width: 950px;
  box-shadow: 0 3px 30px 0 rgba(0, 0, 0, 0.15);
  /* max-width: 896px;
  max-height: 504px; */
}

img.features-img {
  image-rendering: auto;
  image-rendering: crisp-edges;
  height: auto;
  max-width: 100%;
  width: 528;
  /* max-width: 896px;
  max-height: 504px; */
}

.has-shadow {
  box-shadow: 0 12px 32px rgba(20, 35, 64, 0.24);
}

#gastro-efficiency-solutions-section {
  text-align: left;
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
  box-shadow: 0 4px 17px 0 rgba(0, 0, 0, 0.10);
}

#unique-solutions-section {
  text-align: left;
}

#unique-solutions-section .container{
  max-width: none;
}

#unique-solutions-section #unique-solutions-picture{
  position: absolute;
  right: 0px;
  height: 100%;
}

#unique-solutions-picture-col {
  
}

#unique-solutions-text-col {
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-right: 4rem;
}

#eliminate-unwanted-bureaucracy-section {
  text-align: left;
  background: linear-gradient(to right, #322E87, #322E87);
  color: white;
  overflow: hidden;
}

#eliminate-unwanted-bureaucracy-section .container{
  margin-left: 2rem;
}

#eliminate-unwanted-bureaucracy-text-col {
  padding-top: 8.5%;
}

#eliminate-unwanted-bureaucracy-text-col h2,#eliminate-unwanted-bureaucracy-text-col p{
  padding-left: 1rem;
  border-left: 6px solid white;
}

#eliminate-unwanted-bureaucracy-section svg {
  margin-left: 10px;
  height:400px;
}

#features-section {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

#cross-platform-section {
  text-align: left;
}

#cross-platform-container {
  /* position: absolute; */
  margin-top: 3rem;
  background: linear-gradient(#413C9D, #413C9D);
  color: white;
  text-align: left;
  padding-top: 3rem;
  padding-bottom: 2rem;
  margin-left: 5rem;
  overflow: hidden;
  border-radius: 46px 0px 0px 46px;
}

#cross-platform-container svg{
  width: 100%;
  height: 300px;
  /* height: 66%; */
}

#cross-platform-container h3 {
  margin-top: 4rem;
}

#cross-platform-container h3,#cross-platform-container p {
  /* margin-left: 0.3rem; */
  padding-left: 0.4rem;
  /* border-left: 6px solid white; */
}

#our-process-section {
  padding-top: 3rem;
  padding-bottom: 3rem;
  background-color: #6B64FF;
  color: white;
}

#our-process-section svg {
  width: 70%;
}

#about-us-section {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

#contact-section {
  text-align: left;
  color: white;
  background: url('./assets/images/contactImage2.png');
  background-size: auto 100%;
  background-position: center;
  background-repeat: no-repeat;
}

#contact-section .container{
  margin-left: 0px;
  margin-right: 0px;
  max-width: none;
}

#contact-section-form-col {
  background-color: #00000080;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

#contact-section input {
  background-color: #DEDEDE80;
  /* border: 2px solid white; */
  border-radius: 8px;
  /* color: white; */
  outline: none;
  /* box-shadow: 0 3px 30px 0 rgba(0, 0, 0, 0.17); */
  border: none;
  width: 100%;
  margin-bottom: 1rem;
  padding: 12px 10px;
}

#contact-section input::placeholder {
  color: #00000075;
}

#contact-section textarea {
  background-color: #DEDEDE80;
  border: none;
  border-radius: 8px;
  width: 100%;
  padding: 10px 10px;
}

#contact-section textarea::placeholder {
  color: #00000075;
}

#contact-section textarea:focus {
  outline: none !important;
}

#contact-section input[type="submit" i] {
  color: white;
  border: none;
  background: #7B61FF;
  margin-top: 1rem;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 700;
  width: 30%;
}

#curious-section {
  background-color: #306D5C;
  padding-top: 3rem;
  padding-bottom: 3rem;
  margin-bottom: 3rem;
  text-align: left;
  color: white;
}

#curious-section form{
  margin-top: 2rem;
}

#curious-section .form-control {
  border-radius: 40px;
  padding: 28px 40px;
  outline: none;
  border: 1px solid white;
}

#curious-section button.btn {
  border: 1px solid white;
}

#meet-the-team-section {
  min-height: 100vh;
  text-align: left;
}

.card {
  height: 100%;
  border-radius: 20px;
  border: none;
  box-shadow: 10px 20px 30px 0 rgba(0, 0, 0, 0.25);
}

.card-img, .card-img-top {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px
}

.card p {
  font-size: 16px;
}

.card p.card-title {
  margin-bottom: 0.25rem;
  font-weight: bold;
}

.card p.card-subtitle {
  margin-bottom: 1rem;
  font-weight: bold;
  color: #306D5C;
}

.card p.card-text {
  font-weight: normal;
  font-size: 15px;
}

#contact-us-section .container {
  min-height: 100vh;
  text-align: left;
  padding-top: 3rem;
}

#contact-us-section a:hover {
  text-decoration: none;
}

#customers-section {
  padding-top: 40px;
  padding-bottom: 40px;
  /* background-color: #152340; */
  /* color: white; */
}

h2.section-header {
  color: #575757;
  font-weight: 650 !important;
}

#pricing-section {
  padding-top: 40px;
  padding-bottom: 40px;
}

.card-deck .card {
  border: none;
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

#steps-section {
  padding-top: 40px;
  padding-bottom: 40px;
}

.divtest {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 250px;*/
  width: 250px !important;
  background-color: #00008b;
  color: #fff;
  
  font-size: 4em;
}

#cta-section {
  padding-top: 40px;
  padding-bottom: 40px;
  /* background-color: #39b54a; */
}

/* #cta-section .btn {
  background: linear-gradient(135deg, white, white);
  color: #39b54a;
  font-weight: bold;
}

#cta-section .btn:hover {
  background: linear-gradient(135deg, white, white);
  color: #39b54a;
} */

.form-control {
  /* background-color: white; */
  /* border: 2px solid white; */
  border-radius: 0px;
  /* color: white; */
  outline: none;
  /* box-shadow: 0 3px 30px 0 rgba(0, 0, 0, 0.17); */
  border: none;
  border-bottom: 1px #306D5C solid;
}

textarea.form-control {
  border: 1px #306D5C solid;
  border-radius: 10px;
}

/* #cta-section .form-control::placeholder {
  color: white;
} */

#contact-us-section input {
  /* background-color: white; */
  /* border: 2px solid white; */
  border-radius: 0px;
  /* color: white; */
  outline: none;
  /* box-shadow: 0 3px 30px 0 rgba(0, 0, 0, 0.17); */
  border: none;
  border-bottom: 1px #306D5C solid;
  width: 50%;
  margin-bottom: 2rem;
  margin-top: 2rem;
  padding: 5px 10px;
}

#contact-us-section textarea {
  border: 1px #306D5C solid;
  border-radius: 10px;
  width: 100%;
  padding: 10px 10px;
}

#contact-us-section textarea:focus {
  outline: none !important;
}

#contact-us-section input[type="submit" i] {
  color: white;
  border: none;
  background: #306D5C;
  padding: 17px 40px;
  border-radius: 50px;
  font-size: 15px;
  font-weight: 700;
  width: 25%;
  float: right;
}

form {
  justify-content: center;
}

.btn-group {
  margin-bottom: 2rem;
}

#contact-info a {
  background-color: #39b54a;
  border-color: #39b54a;
}

footer {
  padding-top: 50px;
  padding-left: 100px;
  padding-right: 100px;
  color: white;
  background-color: #393939;
  text-align: left;
  margin-top: 4rem;
}

footer .container {
  padding-left: 0;
  padding-right: 0;
}

footer p {
  margin-bottom: 0;
  font-size: 14px;
}

footer svg {
  margin-right: 1rem;
}

footer a {
  cursor: pointer;
  color: white;
}

footer a:hover {
  color: white;
  text-decoration: none;
}

footer h5 {
  font-weight: 650;
}

.main-subsection {
  padding-bottom: 40px;
}

.split-subsection {
  text-align: left;
  padding-top: 40px;
  padding-bottom: 40px;
}

footer svg {
  font-size: 150% !important;
}

footer img {
  image-rendering: auto;
  image-rendering: crisp-edges;
  height: auto;
  max-width: 100%;
  width: 150px;
}

#contact-section svg path {
  fill: #8cc63f;
}

.footer-socials {
  margin: 10px 0;
}

.footer-socials a {
  color: #979797;
}

.footer-socials a:hover {
  color: #979797;
}

/* Pricing section */
#pricing-section .card {
  border: solid 2px transparent;
}

#pricing-section .card:hover {
  border: solid 2px #8cc63f;
}

#pricing-section a {
  color: #8cc63f;
}

#pricing-section a:hover {
  text-decoration: none;
}

.coming-soon-section {
  text-align: center !important;
}

.coming-soon-section a {
  color: #8cc63f;
}

.coming-soon-section a:hover {
  color: #8cc63f;
}

.coming-soon-section svg {
  margin-right: 1rem;
  font-size: 200% !important;
}

nav.navbar {
  background-color: white;
}

.navbar-light .navbar-nav a.nav-link {
  color: #7E7E7E;
}

.navbar-light .navbar-nav a.nav-link:hover {
  color: #7E7E7E;
}

.navbar-light .navbar-nav a.nav-link:focus {
  color: #7E7E7E !important;
}

.navbar-light .navbar-nav .active>.nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show>.nav-link {
  color: #7E7E7E;
  text-decoration: underline;
  text-decoration-color: #322E87;
  text-decoration-thickness: 2.5px;
}

#landing-page #hero-section h3 {
  font-weight: 650;
  margin-bottom: 1rem;
}

#quick-explanation-icons-section {
  padding-top: 60px;
  padding-bottom: 40px;
}

#quick-explanation-icons-section p,h5{
  text-align: left;
}

.align-left {
  text-align: left;
}

form button {
  padding: 8px 20px;
}

#cta-section .section-header {
  margin-top: 5rem;
}

#fruit-basket {
  max-width: 370px;
}

#copyright {
  margin-top: 3rem;
}

#how-it-works-section h1{
  color: #A9CB59;
  font-weight: 650;
}

#how-it-works-section p{
  text-align:left;
  color: #7D7D7D;
  font-weight: 650;
}

#how-it-works-section .section-header{
  margin-bottom: 2rem;
}

.desktop-product-showcase {
  max-width: 90%;
}

.desktop-how-it-works {
  margin-top: 0.8rem;
  max-width: 88%;
  box-shadow: 0 3px 30px 0 rgba(0, 0, 0, 0.17);
  border-radius: 45px;
}

.mobile-how-it-works {
  box-shadow: 0 3px 30px 0 rgba(0, 0, 0, 0.17);
  border-radius: 45px;
}

#impressum-page #hero-section {
  background-image: none;
}

#impressum-page #hero-section h2{
  color: #212529;
  text-align: left;
}

#impressum-page #hero-section p{
  color: #212529;
  font-size: 20px;
  text-align: left;
}

#impressum-page #hero-section .container{
  padding-top: 2rem;
}

#datenchutz #hero-section {
  background: none;
  text-align: left;
}

#datenchutz #hero-section p{
  color: #393939;
  font-size: 20px;
  font-weight: 300;
}

@media (min-width: 1390px) {
  #unique-solutions-text-col .row {
    margin-bottom: 0.5rem;
  }
}

@media (min-width: 768px) {
  #team-cards-row {
    margin-left: -49px;
    margin-right: -49px;
  }

  .navbar {
    padding: 0.5rem 2rem;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .hide-on-desktop {
    display: none;
  }

  .container {
    max-width: 95%;
  }

  #datenchutz #hero-section .container{
    margin-top: 10rem;
  }
}

@media only screen and (max-width: 768px) {
  .hide-on-mobile {
    display: none;
  }

  h1 {
    font-size: 40px;
  }

  h2 {
    font-size: 20px;
  }

  h3 {
    font-size: 16px;
  }
  
  p {
    font-size: 16px;
  }

  .fs-20 {
    font-size: 16px;
  }

  .navbar-collapse a.nav-link {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  #hero-section {
    background-size: auto 120%;
    background-position-x: -550px;
  }

  #hero-text-1 {
    margin-top: 40%;
  }

  #gastro-efficiency-solutions-section {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  #unique-solutions-text-col {
    padding-right: 15px;
    padding-bottom: 0px;
    text-align: center;
  }

  #unique-solutions-text-col .row{
    margin-bottom: 1rem;
  }

  #eliminate-unwanted-bureaucracy-section {
    background: url('./assets/images/eliminateUnwantedBureaucracy.png');
    background-size: 140% auto;
    /* background-position-y: 50px; */
    background-repeat: no-repeat;
  }

  #eliminate-unwanted-bureaucracy-section .container {
    margin-left: 0px;
  }

  #eliminate-unwanted-bureaucracy-text-col {
    padding-top: 2rem;
    padding-bottom: 1rem;
  }

  #features-section {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  #features-section .feature-col {
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 1rem;
  }

  #features-section .align-left {
    text-align: center;
  }

  #cross-platform-container {
    margin-top: 1rem;
    margin-left: 15px;
    padding-top: 1rem;
  }

  #cross-platform-text-col {
    padding-left: 2rem;
  }

  #cross-platform-container h3 {
    margin-top: 0px;
  }

  #our-process-section {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  #our-process-section .mb-3 {
    margin-bottom: 0px !important;
  }

  #our-process-section svg {
    width: 100%;
  }

  #about-us-section {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  #about-us-section p{
    margin-bottom: 0px;
  }

  #contact-section-form-col {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  #contact-section input[type="submit" i] {
    width: 50%;
  }

  #contact-section h2.mb-3 {
    margin-bottom: 0.25rem !important;
  }

  #contact-section h3 {
    margin-bottom: 1rem;
  }

  #meet-the-team-section {
    min-height: 0px;
  }

  #contact-us-section {
    min-height: 0px;
  }

  #contact-us-section .container{
    min-height: 0px;
    padding-bottom: 2rem;
  }

  #contact-section {
    background-size: auto 100%;
    background-position-x: -1045px;
  }

  footer {
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-left: 15px;
    padding-right: 15px;
  }

  #hero-text-1 div {
    margin-bottom: 2rem;
  }

  #hero-text-2 {
    overflow: hidden;
    padding-top: 22.4rem;
  }


  #hero-text-2 img{
    width: 90%;
    position: absolute;
    bottom: 190px;
    left: 115px;
  }

  button.btn {
    font-size: 16px;
  }
  
  button.btn:hover {
    font-size: 12px;
  }

  .card {
    box-shadow: none;
    border: #306D5C25 1px solid;
    margin-right: 1.5rem;
  }

  .react-multi-carousel-list {
    padding-left: 0px;
  }

  #curious-section .form-control{
    padding: 24px 20px;
  }

  #curious-section button.btn {
    padding: 15px 10px;
  }

  #contact-us-section input[type="submit" i] {
    width: 50%;
  }

  #datenchutz #hero-section .container{
    margin-top: 7.5rem;
  }
}